<!--Ez az Admin login-->
<template class="my-auto">
  <div class="text-center center py-auto d-flex">
    <v-card outlined elevation="2" min-width="30%" class="m-auto p-2" border>
      <p class="display-1 my-3">{{ lang.tanulmanyi_tajekoztato }}</p>
      <p class="display-1 my-3">{{ lang.admin_felulet }}</p>
      <v-divider class="mx-2 uni_blue2 size"></v-divider>
      {{ studentName }}
      <v-text-field
        label="NeptunKód"
        class="m-3"
        color="uni_blue2"
        :error-messages="errors.neptunCode"
        v-model="user.neptunCode"
        v-on:keyup.enter="Login"
        :disabled="logged"
      />
      <v-text-field
        :label="lang.login_jelszo"
        class="m-3"
        color="uni_blue2"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        @click:append="show = !show"
        :error-messages="errors.password"
        v-model="user.password"
        v-on:keyup.enter="Login"
        :disabled="logged"
      />
      <v-combobox
        label="Mintatanterv"
        class="m-3"
        color="uni_blue2"
        item-color="uni_blue2"
        :items="sample_plans"
        item-text="Mintatanterv_nev"
        item-value="Mintatanterv_kod"
        v-if="showMintatantev"
        v-model="selectedSamplePlan"
      >
      </v-combobox>
      <v-alert dense outlined type="error" v-if="alert">
        Ilyen neptunkóddal nem található aktív hallgató az adatbázisban!
      </v-alert>
      <v-row>
        <v-col :cols="12" :lg="6" :md="12">
          <v-row>
            <v-col :cols="12" :lg="8" :md="8" :sm="8">
              <v-btn
                dark
                v-if="!showMintatantev"
                class="m-3"
                color="uni_blue"
                @click="Login"
                >{{ lang.bejelentkezes }}</v-btn
              >
            </v-col>
            <v-col :cols="12" :lg="8" :md="8" :sm="8">
              <v-btn
                dark
                v-if="showMintatantev"
                class="m-3"
                color="uni_blue"
                @click="goToMatrix"
              >
                Mátrix
              </v-btn>
            </v-col>
            <v-col :cols="12" :lg="8" :md="8" :sm="8">
              <v-btn
                dark
                v-if="showMintatantev"
                class="m-3"
                color="uni_blue"
                @click="Logout"
              >
                Hallgató váltás
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="12" :lg="6" :md="12">
          <v-btn
            class="my-4 float-right"
            color="uni_blue"
            @click="languageChange('en')"
            elevation="0"
            plain
            >en</v-btn
          >
          <v-btn
            class="my-4 float-right"
            color="uni_blue"
            @click="languageChange('hu')"
            elevation="0"
            plain
            >hu</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  data: () => ({
    show: false,
    user: {
      neptunCode: "",
      password: "",
    },
    errors: {
      neptunCode: "",
      password: "",
    },
    selectedSamplePlan: "",
    lang: hu,
    langName: "hu",
    alert: false,
    showMintatantev: false,
    sample_plans: [],
    logged: false,
    studentName: "",
    student: {},
  }),
  beforeMount() {
    this.params = this.$route.params.id;
    if (this.params != "l5l3bVZgWL3AFhiiu8wfEBvkhLN4N5") {
      this.$router.push("/");
    }
  },
  methods: {
    async Login() {
      // AQAWC1
      this.alert = false;
      this.errors = {
        neptunCode: "",
        password: "",
      };
      try {
        if (this.user.neptunCode.length != 6) {
          this.errors.neptunCode = "Nem megfelelő neptunkód";
          return;
        }
        if (this.user.password != "T@nulm@nyi") {
          this.errors.password = "Nem jó jelszó.";
          return;
        }
        const response = await this.$http.post("/loginTeszt", this.user);
        if (Object.keys(response.errors).length > 0) {
          this.alert = true;
        } else {
          this.showMintatantev = true;
          this.logged = true;
          this.sample_plans = response.sample_plans;
          this.$cookies.set("studentToken", response.token);
          this.$store.commit("setStudent", response.student);
          this.studentName = response.student.F1;
          this.student = response.student;
        }
      } catch (e) {
        console.error(e);
      }
    },
    languageChange(type) {
      if (type == "en") {
        this.lang = en;
        this.langName = "en";
      } else {
        this.lang = hu;
        this.langName = "hu";
      }
      this.$cookies.set("lang", type);
    },

    async goToMatrix() {
      try {
        var post = {
          user: {
            neptunkod: this.student.F0
          },
          sample_plan: {
            modulkod: this.selectedSamplePlan.Modulkod
          }
        }
        const response = await this.$http.post("goToMatrix", post);
        if(response.status == 'OK') {
          this.$router.push("/tanulo_matrix/hu/" + this.selectedSamplePlan.Modulkod + "/" + this.selectedSamplePlan.Mintatanterv_kod);
        }
      } catch (e) {
        console.error(e)
      }
    },

    Logout() {
      this.$cookies.remove("studentToken");
      this.$store.commit("setStudent", null);
      this.user.neptunCode = "";
      this.user.password = "";
      this.sample_plans = [];
      this.showMintatantev = false;
      this.logged = false;
    },
  },
};
</script>

<style>
.center {
  min-height: 100%;
}
.margin {
  margin: auto;
}
.size {
  font-size: 30px;
}
</style>